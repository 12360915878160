import request from '@/utils/request';
import Vue from 'vue';

export function goods_cate_list(data) {
  return request({
    url: '/Taobaoshop/goods_cate_list?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}
export function goods_list(data) {
  return request({
    url: '/Taobaoshop/goods_list?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}

export function cate_goods_list(data) {
  return request({
    url: '/Entershop/goods_list?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}

export function goods_detail(data) {
  return request({
    url: '/Taobaoshop/goods_detail?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}

export function cate_goods_detail(data) {
  return request({
    url: '/Entershop/get_good_detail?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}

//寄售购买
export function cateAddOrder(data) {
  return request({
    url: '/Entershop/add_order?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}
//寄售预约
export function AddAppointment(data) {
  return request({
    url: '/Shop/add_appointment?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}
export function con_goods_list(data) {
  return request({
    url: '/home/goods_list?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}

export function con_goods_detail(data) {
  return request({
    url: '/shop/get_good_detail?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}

export function conAddOrder(data) {
  return request({
    url: '/shop/add_order?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}
//匹配列表
export function matchLists(data) {
  return request({
    url: '/Matchctl/match_lists?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}

//买卖家详情
export function matchDetail(data) {
  return request({
    url: '/Matchctl/match_detail?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}
//上传支付信息
export function uploadPayinfo(data) {
  return request({
    url: '/users/uploadPayinfo?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}
//投诉
export function complain(data) {
  return request({
    url: '/Matchctl/complain?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}
//打款
export function affirm_pay(data) {
  return request({
    url: '/Matchctl/affirm_pay?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}
//确认
export function affirm_gathering(data) {
  return request({
    url: '/Matchctl/affirm_gathering?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}
//已完成订单
export function over_match_lists(data) {
  return request({
    url: '/Matchctl/over_match_lists?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}
//我的商品
export function getMyGoodLists(data) {
  return request({
    url: '/Shop/get_my_good_lists?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}
//我的预约
export function getAppointmentLists(data) {
  return request({
    url: '/shop/get_appointment_lists?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}
//线上商城订单
export function getOrderLists(data) {
  return request({
    url: '/Entershop/get_order_lists?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}
//取消订单
export function CancelCateOrder(data) {
  return request({
    url: '/Entershop/cancel_order?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}

//提货
export function PickupGoods(data) {
  return request({
    url: '/shop/pickup_goods?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}

//提货订单
export function getPickupLists(data) {
  return request({
    url: '/Shop/get_pickup_lists?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}
//分类
export function cate_cate_list(data) {
  return request({
    url: '/Entershop/goods_cate_list?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}
