<template>
  <div class="myGoodsView">
    <van-sticky>
      <van-nav-bar title="我的预约" left-arrow @click-left="onClickLeft" />
    </van-sticky>

    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      loading-text="..."
      @load="onLoad"
    >
      <van-cell v-for="(item, key) in list" :key="key" class="xiax_item">
        <div class="left">
          <van-image width="2.5rem" height="2.5rem" fit="contain" :src="$imageUrl + item.image" />
        </div>
        <div class="right">
          <span class="desc">{{ item.title }}({{ item.status_name }})</span>
          <span class="money">
            订单金额：
            <em>¥{{ item.money }}</em>
          </span>
          <span class="time">预约日期：{{ item.start_time }}</span>
          <span class="time">提交时间：{{ item.addtime }}</span>
        </div>
      </van-cell>
    </van-list>
  </div>
</template>

<script>
import { formatDate } from "@/utils/func";
import { getAppointmentLists } from "@/api/goods";
export default {
  data() {
    return {
      list: [],
      page: 1,
      pageSize: 15,
      loading: false,
      finished: false
    };
  },
  watch: {},
  methods: {
    onClickLeft() {
      this.$router.push("/main/user");
    },
    //日志列表
    get_my_good_lists() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      getAppointmentLists({
        page: this.page,
        pageSize: this.pageSize
      })
        .then(response => {
          this.list.push.apply(this.list, response.result);
          // 加载状态结束
          this.loading = false;
          this.page++;
          if (response.result.length == 0) {
            this.finished = true;
          }
        })
        .catch(error => {});
    },
    onLoad() {
      this.get_my_good_lists();
    }
  }
};
</script>
<style lang="stylus">
.myGoodsView
  .van-list
    margin-top: 0.2rem
    margin: 0.2rem 0.2rem 0
    .xiax_item
      span
        display: block
      .van-cell__value
        display: flex
        .left
          margin-right: 0.3rem
        .right
          color: #777
          font-size: 0.36rem
          .desc
            font-weight: 500
          .money
            em
              color: #f70101
          .time
            em
              color: #c30303
            .van-count-down
              color: #18f309
              display: inline-block
</style>
